<template>
  <v-footer
    id="dashboard-core-footer"
  >
      <v-row
        no-gutters
      >
        <!-- <img src="@/assets/partner/star21.png" height="42px"/> -->
        <v-spacer class="hidden-sm-and-down" />
        <v-col
          cols="12"
          md="auto"
        >
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-right">
            &copy; 2021, powered
            by <a href="http://www.fastsoft.com.au">billview</a>
          </div>
        </v-col>
      </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [
        // {
        //   href: '#',
        //   text: 'Micandi Team'
        // },
        // {
        //   href: '#',
        //   text: 'About Us'
        // },
        // {
        //   href: '#',
        //   text: 'Licenses'
        // }
      ]
    })
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      color: #3c4858
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
